import _ from 'lodash';

const titles = {
    home: 'Natursteinarbeiten in Zürich: Beratung, Renovation, Rekonstruktion und Neubau',
    company: 'Über Abraxas Natursteine AG - Historie und Natursteinarbeiten in Zürich',
    offer: 'Natursteinarbeiten nach Mass - Beratung, Renovation und Neubau',
    references: 'Unsere Natursteinreferenzen - Beeindruckende Arbeiten in Zürich',
};

export const metaDescriptions = {
    home:
        'Ihre Experten für Natursteinarbeiten in Zürich - Abraxas Natursteine AG. Von Beratung und Planung bis zu stilgetreuen Renovationen, Rekonstruktionen und Neubauten. Erfahren Sie höchste Qualität in der Natursteinverarbeitung in Zürich.',
    company:
        'Entdecken Sie die Geschichte von Abraxas Natursteine AG, einem familiengeführten Unternehmen in Zürich, das sich auf Natur- und Kunststeinarbeiten spezialisiert. Erfahren Sie mehr über unsere Leidenschaft für Fassadensanierung, historische Renovationen und moderne Neubauten.',
    offer:
        'Unsere massgeschneiderten Natursteinarbeiten umfassen Beratung, Renovation und Neubau. Mit Fachwissen in Baustilen und Materialzusammensetzungen bieten wir Lösungen für Natursteinprojekte im Innen- und Aussenbereich.',
    references:
        'Erleben Sie unsere eindrucksvollen Natursteinreferenzen in Zürich. Lassen Sie sich von unseren abgeschlossenen Projekten inspirieren und sehen Sie, wie Abraxas Natursteine AG Qualität und Fachkenntnisse vereint.',
};

export const getTitle = page => {
    const title = _.get(titles, page, 'home');
    return `${title} | Abraxas Natursteine AG`;
};
