import React from 'react';
import {Container, Typography} from '@material-ui/core';
import {withTemplate} from './Template';
import {useStyleSections} from '../util/styles';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import {colors} from '../util/colors';
import {menuBreakPoint} from '../util/settings';
import {getTitle, metaDescriptions} from "../util/seo";
import {Helmet} from "react-helmet";

export const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            color: colors.white,
            paddingTop: theme.spacing(10),
            paddingBottom: theme.spacing(10),
            height: 'auto',
            [theme.breakpoints.down(menuBreakPoint)]: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
            },
        },
    }),
);

const Offer = props => {
    const classes = useStyles();
    const sectionClasses = useStyleSections();

    const {color} = props;

    return (
        <React.Fragment>
            <Helmet>
                <title>{getTitle('offer')}</title>
                <meta name="description" content={metaDescriptions.offer} />
            </Helmet>
            <div className={classes.root} style={{backgroundColor: color}}>
                <Container maxWidth="md">
                    <div className={sectionClasses.section}>
                        <Typography className={sectionClasses.sectionHeading} variant="h2">
                            Beratung / Planung
                        </Typography>
                        <div className={sectionClasses.sectionText}>
                            Gerne Beraten und Planen wir für Sie anstehende Natursteinarbeiten im Innen- oder
                            Aussenbereich.
                        </div>
                    </div>
                    <div className={sectionClasses.section}>
                        <Typography className={sectionClasses.sectionHeading} variant="h2">
                            Renovation / Rekonstruktion
                        </Typography>
                        <div className={sectionClasses.sectionText}>
                            Wissen über die verschiedenen Baustile, die historischen und neuzeitlichen Werkzeuge, sowie
                            über
                            Bausubstanzen und deren Zusammensetzungen von früher bis heute, sind für stilgetreue
                            Renovationen und Rekonstruktionen unerlässlich.
                        </div>
                    </div>
                    <div className={sectionClasses.section}>
                        <Typography className={sectionClasses.sectionHeading} variant="h2">
                            Neubau
                        </Typography>
                        <div className={sectionClasses.sectionText}>
                            Vermehrt werden neue Gebäude wieder in Naturstein erstellt. Meist nicht massiv sondern
                            vorgehängt. Wir besitzen für beide Methoden das notwendige Fachwissen.
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTemplate(Offer);
