export const references = [
    {
        enabled: true,
        slug: 'zuerich-brannhof',
        title: 'Zürich, Brannhof',
        description:
            'Zürich, Bahnhofstrasse 75/79: Ausführung von Natur- und Kunststeinarbeiten in einer Arbeitsgemeinschaft von Mai 2021 bis Otober 2023.',
        imageFolder: 'zuerich-brannhof',
        images: [
            {
                lq: '01-gebaeude-manor_zuerich-lq.jpg',
                hq: '01-gebaeude-manor_zuerich-hq.jpg',
                hf: '01-gebaeude-manor_zuerich-hf.jpg',
            },
            {
                lq: '02-gebaeude-manor_zuerich-lq.jpg',
                hq: '02-gebaeude-manor_zuerich-hq.jpg',
                hf: '02-gebaeude-manor_zuerich-hf.jpg',
            },
            {
                lq: '03-gebaeude-manor_zuerich-lq.jpg',
                hq: '03-gebaeude-manor_zuerich-hq.jpg',
                hf: '03-gebaeude-manor_zuerich-hf.jpg',
            },
            {
                lq: '04-gebaeude-manor_zuerich-lq.jpg',
                hq: '04-gebaeude-manor_zuerich-hq.jpg',
                hf: '04-gebaeude-manor_zuerich-hf.jpg',
            },
            {
                lq: '05-gebaeude-manor_zuerich-lq.jpg',
                hq: '05-gebaeude-manor_zuerich-hq.jpg',
                hf: '05-gebaeude-manor_zuerich-hf.jpg',
            },
        ],
    },
    {
        enabled: true,
        slug: 'brunnen',
        title: 'Brunnen',
        description: 'Sanierungen und neue Brunnentröge aus Kunststein,' + ' Findlingen und Sandstein.',
        imageFolder: 'brunnen',
        images: [
            {
                lq: '01-brunnen_lq.jpg',
                hq: '01-brunnen_hq.jpg',
                hf: '01-brunnen_hf.jpg',
            },
            {
                lq: '02-brunnen_lq.jpg',
                hq: '02-brunnen_hq.jpg',
                hf: '02-brunnen_hf.jpg',
            },
            {
                lq: '03-brunnen_lq.jpg',
                hq: '03-brunnen_hq.jpg',
                hf: '03-brunnen_hf.jpg',
            },
            {
                lq: '04-brunnen_lq.jpg',
                hq: '04-brunnen_hq.jpg',
                hf: '04-brunnen_hf.jpg',
            },
            {
                lq: '05-brunnen_lq.jpg',
                hq: '05-brunnen_hq.jpg',
                hf: '05-brunnen_hf.jpg',
            },
            {
                lq: '06-brunnen_lq.jpg',
                hq: '06-brunnen_hq.jpg',
                hf: '06-brunnen_hf.jpg',
            },
            {
                lq: '07-brunnen_lq.jpg',
                hq: '07-brunnen_hq.jpg',
                hf: '07-brunnen_hf.jpg',
            },
        ],
    },
    {
        enabled: true,
        slug: 'zuerich-hofackerstrasse',
        title: 'Zürich, Hofackerstrasse',
        description:
            'Natursteinstützmauer Hofackerstrasse 1-5, Zürich. Bestehende Stützmauer aus Sandstein mit bossierten Werkstücken ergänzen.',
        imageFolder: 'zuerich-hofackerstrasse',
        images: [
            {
                lq: '01-mauer-hofackerstrasse-lq.jpg',
                hq: '01-mauer-hofackerstrasse-hq.jpg',
                hf: '01-mauer-hofackerstrasse-hf.jpg',
            },
            {
                lq: '02-mauer-hofackerstrasse-lq.jpg',
                hq: '02-mauer-hofackerstrasse-hq.jpg',
                hf: '02-mauer-hofackerstrasse-hf.jpg',
            },
            {
                lq: '03-mauer-hofackerstrasse-lq.jpg',
                hq: '03-mauer-hofackerstrasse-hq.jpg',
                hf: '03-mauer-hofackerstrasse-hf.jpg',
            },
            {
                lq: '04-mauer-hofackerstrasse-lq.jpg',
                hq: '04-mauer-hofackerstrasse-hq.jpg',
                hf: '04-mauer-hofackerstrasse-hf.jpg',
            },
        ],
    },
    {
        enabled: true,
        slug: 'treppen',
        title: 'Treppen',
        description:
            'Treppenanlagen aus diversen Natursteinen sowie Betonwerkstein und Versetzarbeiten von Treppen in Blocktritt.',
        imageFolder: 'treppen',
        images: [
            {
                lq: '01-treppe_kappel-am-albis_lq.jpg',
                hq: '01-treppe_kappel-am-albis_hq.jpg',
                hf: '01-treppe_kappel-am-albis_hf.jpg',
            },
            {
                lq: '02-treppe_kappel-am-albis_lq.jpg',
                hq: '02-treppe_kappel-am-albis_hq.jpg',
                hf: '02-treppe_kappel-am-albis_hf.jpg',
            },
            {
                lq: '03-treppe_kappel-am-albis_lq.jpg',
                hq: '03-treppe_kappel-am-albis_hq.jpg',
                hf: '03-treppe_kappel-am-albis_hf.jpg',
            },
            {
                lq: '04-schule-sihlfeld-lq.jpg',
                hq: '04-schule-sihlfeld-hq.jpg',
                hf: '04-schule-sihlfeld-hf.jpg',
            },
            {
                lq: '05-schule-sihlfeld-lq.jpg',
                hq: '05-schule-sihlfeld-hq.jpg',
                hf: '05-schule-sihlfeld-hf.jpg',
            },
        ],
    },
    {
        enabled: true,
        slug: 'schulhaus-hofacker',
        title: 'Zürich, Schulhaus Hofacker',
        description:
            'Renovation von historischem Stadtschulhaus. Fassadenreinigung und Instandhaltung der Fassade mit Teilersatz.',
        imageFolder: 'schulhaus-hofacker',
        images: [
            {
                lq: '01-schulhaus-hofacker-lq.jpg',
                hq: '01-schulhaus-hofacker-hq.jpg',
                hf: '01-schulhaus-hofacker-hf.jpg',
            },
            {
                lq: '02-schulhaus-hofacker-lq.jpg',
                hq: '02-schulhaus-hofacker-hq.jpg',
                hf: '02-schulhaus-hofacker-hf.jpg',
            },
            {
                lq: '03-schulhaus-hofacker-lq.jpg',
                hq: '03-schulhaus-hofacker-hq.jpg',
                hf: '03-schulhaus-hofacker-hf.jpg',
            },
            {
                lq: '04-schulhaus-hofacker-lq.jpg',
                hq: '04-schulhaus-hofacker-hq.jpg',
                hf: '04-schulhaus-hofacker-hf.jpg',
            },
        ],
    },
    {
        enabled: true,
        slug: 'villa-rosau',
        title: 'Zürich, Villa Rosau',
        description:
            'Renovation Villa aus Sandstein Bolligen, Lehholz inkl. technischer Arbeiten. Neubau Stützmauer Schanzengraben aus Sandstein. Instandstellung Einfriedung mit Neubauteilen in Cresciano.',
        imageFolder: 'villa-rosau',
        images: [
            {
                lq: '01-villa-rosau-lq.jpg',
                hq: '01-villa-rosau-hq.jpg',
                hf: '01-villa-rosau-hf.jpg',
            },
            {
                lq: '02-villa-rosau-lq.jpg',
                hq: '02-villa-rosau-hq.jpg',
                hf: '02-villa-rosau-hf.jpg',
            },
            {
                lq: '03-villa-rosau-lq.jpg',
                hq: '03-villa-rosau-hq.jpg',
                hf: '03-villa-rosau-hf.jpg',
            },
            {
                lq: '04-villa-rosau-lq.jpg',
                hq: '04-villa-rosau-hq.jpg',
                hf: '04-villa-rosau-hf.jpg',
            },
            {
                lq: '05-villa-rosau-lq.jpg',
                hq: '05-villa-rosau-hq.jpg',
                hf: '05-villa-rosau-hf.jpg',
            },
            {
                lq: '06-villa-rosau-lq.jpg',
                hq: '06-villa-rosau-hq.jpg',
                hf: '06-villa-rosau-hf.jpg',
            },
            {
                lq: '07-villa-rosau-lq.jpg',
                hq: '07-villa-rosau-hq.jpg',
                hf: '07-villa-rosau-hf.jpg',
            },
            {
                lq: '08-villa-rosau-lq.jpg',
                hq: '08-villa-rosau-hq.jpg',
                hf: '08-villa-rosau-hf.jpg',
            },
        ],
    },
    {
        enabled: true,
        slug: 'gemeindehaus-meilen',
        title: 'Meilen, Gemeindehaus',
        description:
            'Erstellen von Vorsatz-Sichtmauerwerk inkl. Wärmedämmung 20 cm aus Alpenkalk. Montage und Dimensionierung der Unterkonstruktion aus A4-Stahl. Ausgeführt für Strabag.',
        imageFolder: 'gemeindehaus-meilen',
        images: [
            {
                lq: '01-gemeindehaus-meilen-lq.jpg',
            },
            {
                lq: '02-gemeindehaus-meilen-lq.jpg',
            },
            {
                lq: '03-gemeindehaus-meilen-lq.jpg',
            },
        ],
    },
    {
        enabled: true,
        slug: 'fraumuensterstrasse',
        title: 'Fraumünsterstrasse',
        description:
            'Instandstellung Fassade aus Savonnieres Kalkstein, Rekonstruktion Balkone und Rundbögen. Detailplanung. Bauleitung Novaron Architekten' +
            ' AG, Hr. Busalt.',
        imageFolder: 'fraumuensterstrasse',
        images: [
            {
                lq: '01-fraumuensterstrasse-lq.jpg',
            },
            {
                lq: '02-fraumuensterstrasse-lq.jpg',
            },
            {
                lq: '03-fraumuensterstrasse-lq.jpg',
            },
        ],
    },
    {
        enabled: true,
        slug: 'hauserstrasse',
        title: 'Hauserstrasse',
        description:
            'Hauserstrasse Instandstellung Fassade aus Sandstein, Rekonstruktion Treppe. Bauleitung BGS & Partner Architekten AG.',
        imageFolder: 'hauserstrasse',
        images: [
            {
                lq: '01-hauserstrasse-lq.jpg',
            },
            {
                lq: '02-hauserstrasse-lq.jpg',
            },
            {
                lq: '03-hauserstrasse-lq.jpg',
            },
        ],
    },
    {
        enabled: true,
        slug: 'kirche-st-anton',
        title: 'Kirche St. Anton',
        description:
            'Kirche St. Anton Sanierung der Kirche aus Sandstein. In ARGE mit Arnet & Cie. AG. Bauleitung Moser Architekten.',
        imageFolder: 'kirche-st-anton',
        images: [
            {
                lq: '01-kirche-stanton-lq.jpg',
            },
            {
                lq: '02-kirche-stanton-lq.jpg',
            },
        ],
    },
    {
        enabled: true,
        slug: 'zuerich-susenbergstrasse',
        title: 'Susenbergstrasse',
        description:
            'Susenbergstrasse Verlegen von grossformatigen Platten als fugenloser Bodenbelag im Kreuzverband. Ausgeführt für Halter Bauservice AG',
        imageFolder: 'zuerich-susenbergstrasse',
        images: [
            {
                lq: '01-susenbergstrasse-lq.jpg',
            },
            {
                lq: '02-susenbergstrasse-lq.jpg',
            },
            {
                lq: '03-susenbergstrasse-lq.jpg',
            },
        ],
    },
];
