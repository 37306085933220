import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../util/colors';
import { Link } from '@material-ui/core';
import { menuBreakPoint } from '../../util/settings';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            color: colors.black,
            paddingBottom: theme.spacing(4),
        },
        contact: {
            fontSize: '2rem',
            fontWeight: theme.fontWeight.mediumPlus,
            [theme.breakpoints.down(menuBreakPoint)]: {
                fontSize: '1.5rem',
            },
        },
        address: {
            marginTop: theme.spacing(2),
            fontStyle: 'normal',
            fontSize: '1.3rem',
            [theme.breakpoints.down(menuBreakPoint)]: {
                marginTop: theme.spacing(1),
                fontSize: '1rem',
            },
        },
        addressHeading: {
            textDecoration: 'underline',
            fontWeight: 'bold',
        },
    }),
);

const Contact = () => {
    const classes = useStyles();
    return (
        <div className={classes.root} style={{ backgroundColor: colors.white, zIndex: '99', position: 'relative' }}>
            <div className={classes.contact}>
                <div>
                    <Link target={'blank'} href={'tel: +41 44 764 00 62'} color={'inherit'} underline={'none'}>
                        044 764 00 62
                    </Link>
                </div>
                <div>
                    <Link target={'blank'} href={'mailto: info@abraxasag.ch'} color={'inherit'} underline={'none'}>
                        info@abraxasag.ch
                    </Link>
                </div>
            </div>
            <address className={classes.address}>
                <div className={classes.addressHeading}>Büro</div>
                <div>Abraxas Natursteine AG</div>
                <div>Weiermattstrasse 29</div>
                <div>8926 Uerzlikon</div>
            </address>
            <address className={classes.address}>
                <div className={classes.addressHeading}>Magazin</div>
                <div>Abraxas Natursteine AG</div>
                <div>Albisstrasse 11</div>
                <div>8915 Hausen am Albis</div>
            </address>
        </div>
    );
};

export default Contact;
