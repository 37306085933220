import List from '@material-ui/core/List';
import {Collapse, Link} from '@material-ui/core';
import React, {useState} from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import logo from '../../assets/images/logo.svg';
import pdf from '../../assets/files/datenschutzerklearung.pdf';
import {useHistory} from 'react-router-dom';
import {menuBreakPoint} from '../../util/settings';
import {colors} from '../../util/colors';
import Contact from './Contact';

const useStyles = makeStyles(theme =>
    createStyles({
        itemList: {
            marginTop: theme.spacing(5),
            [theme.breakpoints.down(menuBreakPoint)]: {
                marginTop: theme.spacing(4),
            },
        },
        menuItem: {
            color: colors.greyAsparagus,
            fontWeight: theme.fontWeight.mediumPlus,
            fontFamily: "'Yamanatrav', sans-serif",
            fontSize: theme.typography.h3.fontSize,
            marginBottom: theme.spacing(3),
            [theme.breakpoints.down(menuBreakPoint)]: {
                fontSize: '1.8rem',
                marginBottom: theme.spacing(1.5),
            },
            '&:hover': {
                color: colors.santaFe,
            },
        },
        menuItemSelected: {
            color: colors.santaFe,
        },
        logo: {
            width: '100%',
            cursor: 'pointer',
        },
        link: {
            color: colors.greyAsparagus,
            fontWeight: theme.fontWeight.regular,
            '&:hover': {
                color: colors.santaFe,
            },
            position: 'absolute',
            bottom: '4rem',
        },
    }),
);

const DrawerList = props => {
    const classes = useStyles();
    const history = useHistory();
    const { activeItem, toggleDrawer } = props;

    const navigate = location => {
        history.push(location);
        toggleDrawer(false);
    };
    const [contactCollapsed, collapseContact] = useState(false);

    return (
        <div
            role="presentation"
            onKeyDown={e => {
                toggleDrawer(false);
            }}
            style={{ height: '100%' }}
        >
            <List>
                <img
                    alt={'Abraxas AG'}
                    className={classes.logo}
                    src={logo}
                    onClick={e => {
                        navigate('/');
                    }}
                />
                <div className={classes.itemList}>
                    {['angebot', 'unternehmen', 'referenzen'].map((text, index) => (
                        <Link
                            key={index}
                            className={`${classes.menuItem} ${activeItem !== text ? '' : classes.menuItemSelected}`}
                            underline="none"
                            component="button"
                            onClick={e => {
                                navigate(`/${text}`);
                            }}
                        >
                            {text.toUpperCase()}
                        </Link>
                    ))}
                    <Link
                        className={classes.menuItem}
                        onClick={e => {
                            collapseContact(!contactCollapsed);
                        }}
                        underline="none"
                        component="button"
                    >
                        KONTAKT
                    </Link>
                    <Collapse in={contactCollapsed}>
                        <Contact />
                    </Collapse>
                </div>
            </List>
            <Link className={classes.link} color="initial" underline="none" target="_blank" href={pdf}>
                Datenschutzerklärung
            </Link>
        </div>
    );
};

export default DrawerList;
