import React, { useState } from 'react';
import { withTemplate } from '../Template';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../util/colors';
import { menuBreakPoint } from '../../util/settings';
import { Box, Collapse, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import IconButton from '@material-ui/core/IconButton';
import { Apps as GridIcon, ArrowBackIos, ArrowForwardIos, Close, Info } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import { getReferenceBySlug } from '../../util/references';
import { Helmet } from 'react-helmet';
import ResponsiveImage from './ResponsiveImage';

export const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            flexGrow: 1,
            color: colors.white,
            height: '100%',
            overflowX: 'hidden',
        },
        swiper: {
            height: '100%',
            '& img': {
                objectFit: 'cover',
                width: '100%',
                height: '100%',
                display: 'block',
            },
        },
        caption: {
            position: 'absolute',
            bottom: 0,
            [theme.breakpoints.up('lg')]: {
                width: '100%',
            },
            [theme.breakpoints.down(menuBreakPoint)]: {
                padding: 0,
                backgroundColor: 'transparent',
            },
            backgroundColor: colors.white,
            color: colors.black,
            zIndex: 99,
        },
        captionText: {
            fontSize: 16,
            backgroundColor: colors.white,
            width: '100%',
            [theme.breakpoints.up('md')]: {
                fontSize: 20,
            },
            '& div': {
                padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
                [theme.breakpoints.down(menuBreakPoint)]: {
                    paddingLeft: theme.spacing(3),
                    paddingRight: theme.spacing(3),
                },
            },
        },
        iconButton: {
            fontSize: 38,
            [theme.breakpoints.down(menuBreakPoint)]: {
                fontSize: 30,
                color: colors.white,
            },
            color: colors.greyAsparagus,
        },
        iconMobile: {
            padding: 8,
        },
        buttonMobile: {
            position: 'absolute',
            top: '50%',
            zIndex: 1,
        },
        '@keyframes bounce': {
            '0%, 6%, 10%, 20%': {
                transform: 'translateY(0)',
            },
            '3%': {
                transform: 'translateY(-10px)',
            },
            '8%': {
                transform: 'translateY(-5px)',
            },
        },
        buttonMobileRight: {
            position: 'absolute',
            right: 0,
            color: colors.greyAsparagus,
            [theme.breakpoints.down(menuBreakPoint)]: {
                color: colors.white,
                animation: '$bounce 8s .2s ease infinite',
            },
        },
        controls: {
            padding: theme.spacing(2),
            [theme.breakpoints.down(menuBreakPoint)]: {
                fontSize: 30,
                padding: 0,
            },
        },
    }),
);

const Reference = props => {
    const referenceSlug = props.match.params.reference;
    const reference = getReferenceBySlug(referenceSlug);
    const classes = useStyles();
    const history = useHistory();
    const [swiper, setSwiper] = useState(null);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const [infoVisible, setInfoVisible] = useState(false);

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Referenzprojekt: ${reference.title} | Abraxas Natursteine AG`}</title>
                <meta name="description" content={reference.description} />
            </Helmet>
            <Box className={classes.root}>
                <Swiper containerClass={classes.swiper} getSwiper={setSwiper} loop={true}>
                    {reference.images.map((img, key) => (
                        <div key={`${reference.slug}-img-${key}`}>
                            <ResponsiveImage reference={reference} img={img} />
                        </div>
                    ))}
                </Swiper>

                <Container maxWidth={false} className={classes.caption}>
                    <Hidden mdUp>
                        <Grid item xs={12}>
                            <div style={{ textAlign: 'center' }} className={classes.controls}>
                                <IconButton classes={{ root: classes.iconMobile }} onClick={goPrev}>
                                    <ArrowBackIos className={classes.iconButton} />
                                </IconButton>
                                <IconButton
                                    classes={{ root: classes.iconMobile }}
                                    onClick={() => {
                                        history.push(`/referenzen`);
                                    }}
                                >
                                    <GridIcon className={classes.iconButton} />
                                </IconButton>
                                <IconButton classes={{ root: classes.iconMobile }} onClick={goNext}>
                                    <ArrowForwardIos className={classes.iconButton} />
                                </IconButton>

                                <IconButton
                                    classes={{
                                        root: classes.buttonMobileRight,
                                    }}
                                    onClick={() => {
                                        setInfoVisible(!infoVisible);
                                    }}
                                >
                                    {infoVisible ? <Close /> : <Info />}
                                </IconButton>
                            </div>
                        </Grid>
                    </Hidden>

                    <Collapse in={matches || infoVisible}>
                        <Grid container>
                            <Grid item xs={12} md={9}>
                                <div className={classes.captionText}>
                                    <div>
                                        <strong>{reference.title} </strong>
                                        <span>{reference.description}</span>
                                    </div>
                                </div>
                            </Grid>

                            <Hidden smDown>
                                <Grid item xs={3}>
                                    <div style={{ textAlign: 'center' }} className={classes.controls}>
                                        <IconButton aria-label="delete" onClick={goPrev}>
                                            <ArrowBackIos className={classes.iconButton} />
                                        </IconButton>
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => {
                                                history.push(`/referenzen`);
                                            }}
                                        >
                                            <GridIcon className={classes.iconButton} />
                                        </IconButton>
                                        <IconButton aria-label="delete" onClick={goNext}>
                                            <ArrowForwardIos className={classes.iconButton} />
                                        </IconButton>
                                    </div>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Collapse>
                </Container>
            </Box>
        </React.Fragment>
    );
};

export default withTemplate(Reference);
