import {references} from "../assets/references/references";
import _ from 'lodash';

export const getReferences = () => {
  return references
  .filter(r => r.enabled);
}

export const getFirstImageUrl = (reference) => {
  const image = _.first(reference.images);
  return `/images/references/${reference.imageFolder}/${image["lq"]}`;
}

export const getReferenceBySlug = (slug) => {
  return _.find(references, (ref) => ref.slug === slug);
}

export const getDefaultImageUrl = (reference, image) => {
  return `/images/references/${reference.imageFolder}/${image["lq"]}`;
}

export const getPortraitImage = (reference, image) => {
  if (_.has(image, "hf")){
    return `/images/references/${reference.imageFolder}/${image["hf"]}`;
  }
}

export const getHighQualityImage = (reference, image) => {
  if (_.has(image, "hq")){
    return `/images/references/${reference.imageFolder}/${image["hq"]}`;
  }
}