import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { SwipeableDrawer } from '@material-ui/core';
import DrawerList from './DrawerList';
import { menuBreakPoint } from '../../util/settings';

const drawerWidth = 380;
const drawerWidthSm = 240;

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            display: 'flex',
        },
        drawerPaper: {
            width: drawerWidth,
            padding: theme.spacing(3),
            [theme.breakpoints.down(menuBreakPoint)]: {
                width: drawerWidthSm,
            },
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
    }),
);

function Drawer(props) {
    const classes = useStyles();
    const { isDrawerOpen, toggleDrawer } = props;

    const doToggleDrawer = isOpen => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        toggleDrawer(isOpen);
    };

    return (
        <div>
            <SwipeableDrawer
                anchor="right"
                open={isDrawerOpen}
                onClose={doToggleDrawer(false)}
                onOpen={doToggleDrawer(true)}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <DrawerList
                    activeItem={props.activeItem}
                    toggleDrawer={isOpen => {
                        doToggleDrawer(isOpen);
                    }}
                />
            </SwipeableDrawer>
        </div>
    );
}

export default Drawer;
