import React from 'react';
import 'swiper/css/swiper.css';
import {getDefaultImageUrl, getHighQualityImage, getPortraitImage} from "../../util/references";

const ResponsiveImage = props => {
  const reference = props.reference;
  const img = props.img;

  return (
      <picture>
        {getPortraitImage(reference, img) !== undefined &&
            <source
                media="(orientation: portrait)"
                srcSet={`${getPortraitImage(reference, img)} 1200w`}
                sizes="1200px"
            />
        }
        {getHighQualityImage(reference, img) !== undefined &&
            <source
                media="(min-width: 2000px)"
                srcSet={`${getHighQualityImage(reference,
                    img)} 2500w`}
                sizes="2500px"
            />
        }
        <source
            srcSet={`${getDefaultImageUrl(reference, img)} 1920w`}
            sizes="1920px"
        />
        <img alt={reference.description} src={getDefaultImageUrl(reference, img)}/>
      </picture>
  );
};

export default ResponsiveImage;
