import React, { useState } from 'react';
import { withTemplate } from './Template';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../util/colors';
import { gridSpacing, menuBreakPoint } from '../util/settings';
import { Card, Container, Grid } from '@material-ui/core';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { KeyboardArrowDown } from '@material-ui/icons';
import Hidden from '@material-ui/core/Hidden';
import { getTitle, metaDescriptions } from '../util/seo';
import { Helmet } from 'react-helmet';
import { getFirstImageUrl, getReferences } from '../util/references';

export const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            color: colors.white,
            height: 'auto',
            flexGrow: 1,
            overflowX: 'hidden',
        },
        reference: {
            position: 'relative',
            paddingBottom: '125%',
            [theme.breakpoints.down(menuBreakPoint)]: {
                paddingBottom: '50%',
            },
            height: 0,
            maxHeight: '650px',
            borderRadius: '0px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto 100%',
            backgroundPosition: 'center top',
        },
        referenceDescription: {
            width: '100%',
            textAlign: 'center',
            top: '40%',
            left: '0',
            padding: '0 15px',
            position: 'relative',
            color: colors.greyAsparagus,
            fontSize: 32,
            fontWeight: theme.fontWeight.mediumPlus,
        },
        overlay: {
            position: 'absolute',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            top: 0,
            left: 0,
            opacity: 0,
            transition: theme.transitions.create(['all'], {
                duration: theme.transitions.duration.complex,
            }),
            '&:hover': {
                cursor: 'pointer',
                opacity: 1,
            },
            width: '100%',
            height: '100%',
        },
        '@keyframes bounce': {
            '0%, 20%, 50%, 80%, 100%': {
                transform: 'translateY(0)',
            },
            '40%': {
                transform: 'translateY(-30px)',
            },
            '60%': {
                transform: 'translateY(-15px)',
            },
        },
        arrowDown: {
            display: 'block',
            position: 'fixed',
            bottom: 0,
            left: '50%',
            marginLeft: -25,
            zIndex: '1',
            animation: '$bounce 1.5s .2s ease infinite',
            '& svg': {
                fontSize: 50,
                color: colors.black,
                opacity: 0.7,
                [theme.breakpoints.down(menuBreakPoint)]: {
                    color: colors.white,
                },
            },
        },
    }),
);

const References = () => {
    const classes = useStyles();
    let history = useHistory();

    const navigate = reference => {
        history.push(`/referenzen/${reference}`);
    };

    const [hideArrow, setHideArrow] = useState(false);

    const handleScroll = e => {
        const scrollTop = e.target.scrollTop;
        setHideArrow(scrollTop > 50);
    };

    const references = getReferences();

    return (
        <React.Fragment>
            <Helmet>
                <title>{getTitle('references')}</title>
                <meta name="description" content={metaDescriptions.references} />
            </Helmet>
            <div onScroll={handleScroll} className={classes.root} style={{ backgroundColor: colors.white }}>
                <Container disableGutters maxWidth={false}>
                    <Grid container spacing={gridSpacing}>
                        {_.map(references, (v, k) => (
                            <Grid key={k} item xs={12} sm={6} md={4}>
                                <Card
                                    className={classes.reference}
                                    onClick={() => navigate(v.slug)}
                                    style={{ backgroundImage: `url(${getFirstImageUrl(v)})`, backgroundSize: 'cover' }}
                                >
                                    <div className={classes.overlay}>
                                        <div className={classes.referenceDescription}>
                                            <span>{v.title}</span>
                                        </div>
                                    </div>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
                {!hideArrow && (
                    <Hidden mdUp>
                        <div className={classes.arrowDown}>
                            <KeyboardArrowDown />
                        </div>
                    </Hidden>
                )}
            </div>
        </React.Fragment>
    );
};

export default withTemplate(References);
