import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { menuBreakPoint } from '../../util/settings';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Container, Link, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            height: theme.spacing(10),
            paddingTop: theme.spacing(3),
            [theme.breakpoints.down(menuBreakPoint)]: {
                height: theme.spacing(5),
                paddingTop: theme.spacing(1),
                paddingRight: theme.spacing(2),
            },
        },
        menuButton: {
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
            [theme.breakpoints.down(menuBreakPoint)]: {
                paddingBottom: '10px',
            },
            [`${theme.breakpoints.down(menuBreakPoint)} and (orientation: landscape)`]: {
                paddingBottom: '6px',
                paddingTop: '10px',
            },
            float: 'right',
            marginRight: theme.spacing(-4),
            marginTop: theme.spacing(-1.5),
        },
        linkButton: {
            [theme.breakpoints.down(menuBreakPoint)]: {
                display: 'none',
            },
            float: 'right',
            textTransform: 'uppercase',
            borderBottom: '4px solid black',
            fontSize: theme.spacing(4.5),
        },
        heading: {
            fontWeight: theme.fontWeight.mediumPlus,
            fontSize: '3rem',
            textTransform: 'uppercase',
            [theme.breakpoints.down(menuBreakPoint)]: {
                fontSize: 30,
                marginBottom: theme.spacing(0),
            },
            display: 'inline-block',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    }),
);

const Topnav = props => {
    const classes = useStyles();
    const { heading, toggleDrawer, color } = props;
    const history = useHistory();
    return (
        <Container maxWidth={false}>
            <div className={classes.root}>
                {heading && (
                    <Typography
                        style={{ color: color, lineHeight: "1.167" }}
                        className={classes.heading}
                        gutterBottom
                        onClick={() => {
                            history.push(`/${heading}`);
                        }}
                    >
                        {heading}
                    </Typography>
                )}

                <Link
                    onClick={() => {
                        toggleDrawer(true);
                    }}
                    className={classes.linkButton}
                    color="textPrimary"
                    underline="none"
                    component="button"
                    variant="h4"
                >
                    Menu
                </Link>
                <IconButton
                    onClick={() => {
                        toggleDrawer(true);
                    }}
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    className={classes.menuButton}
                >
                    <MenuIcon fontSize="large" />
                </IconButton>
            </div>
        </Container>
    );
};

export default Topnav;
