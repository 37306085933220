import React from 'react';
import {withTemplate} from './Template';
import {Container, Grid, Typography} from '@material-ui/core';
import {colors} from '../util/colors';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import {useStyleSections} from '../util/styles';
import {menuBreakPoint} from '../util/settings';
import employeeImage from '../assets/employees/01-portrait-mitarbeiter_lq.jpg';
import {getTitle, metaDescriptions} from "../util/seo";
import {Helmet} from "react-helmet";

const useStyles = makeStyles(theme =>
    createStyles({
      root: {
        color: colors.white,
        paddingTop: theme.spacing(10),
        height: 'auto',
        [theme.breakpoints.down(menuBreakPoint)]: {
          paddingTop: theme.spacing(4),
        },
      },
      rootEmpl: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      card: {
        borderRadius: '0px',
        boxShadow: 0,
      },
      imageDescription: {
        margin: "auto",
        padding: theme.spacing(2)
      }
    }),
);

const Company = props => {
  const classes = useStyles();
  const sectionClasses = useStyleSections();
  const {color} = props;

  return (
      <React.Fragment>
        <Helmet>
          <title>{getTitle('company')}</title>
          <meta name="description" content={metaDescriptions.company}/>
          <link rel="canonical" href="https://abraxasag.ch/unternehmen"/>
        </Helmet>
        <div className={classes.root} style={{backgroundColor: color}}>
          <Container maxWidth="md">
            <div className={sectionClasses.section}>
              <Typography className={sectionClasses.sectionHeading} variant="h2">
                GESCHICHTE
              </Typography>
              <div className={sectionClasses.sectionText}>
                Wir sind ein kleines familiengeführtes Unternehmen aus der Region Zürich. Die Abraxas
                Natursteine AG wurde 1993 von Viktor Häberling gegründet und führt sämtliche Arbeiten in
                Natur- und Kunststein aus. Unsere Haupttätigkeit betrifft das Sanieren von Fassaden aus
                Naturstein an historischen Gebäuden, Sakralbauten, Brunnen, Treppen aus Sandstein,
                Bodenbeläge, etc.
              </div>
            </div>
          </Container>
        </div>
        <div className={classes.rootEmpl} style={{backgroundColor: colors.white}}>
          <Container disableGutters maxWidth={false}>
            <Grid container>
              <img style={{width: "100%", height: "auto"}} alt="v.l.n.r. Salvatore Luperto, Wanja Häberling, Dipl. Techiniker HF Bauführung, Viktor Häberling Eidg. dip.'
                    + ' Steinmetzmeister"
                   src={employeeImage}/>
              <div className={classes.imageDescription}>v.l.n.r. <b>Salvatore Luperto</b>, <b>Wanja Häberling</b> Dipl. Techiniker HF Bauführung, <b>Viktor
                Häberling</b> Eidg. dip. Steinmetzmeister
              </div>
            </Grid>
          </Container>
        </div>
      </React.Fragment>
  );
};

export default withTemplate(Company);
