import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Home from './components/Home';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Offer from './components/Offer';
import {colors} from './util/colors';
import Company from './components/Company';
import References from './components/References';
import Reference from './components/reference/Reference';
import {Helmet} from "react-helmet";
import {getTitle, metaDescriptions} from "./util/seo";

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            display: 'flex',
            flexFlow: 'column',
            height: '100%',
        },
    }),
);

function App() {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Helmet>
                <title>{getTitle('home')}</title>
                <meta name="description" content={metaDescriptions.home}/>
                <link rel="canonical" href="https://abraxasag.ch" />
            </Helmet>
            <div className={classes.root}>
                <Router>
                    <Route exact path="/unternehmen">
                        <Company activeItem="unternehmen" color={colors.makara}/>
                    </Route>
                    <Route exact path="/angebot">
                        <Offer activeItem="angebot" color={colors.greyAsparagus}/>
                    </Route>
                    <Route exact path="/referenzen">
                        <References activeItem="referenzen" color={colors.greyAsparagus}/>
                    </Route>
                    <Route
                        exact
                        path="/referenzen/:reference"
                        render={props => <Reference activeItem="referenzen" color={colors.greyAsparagus} {...props} />}
                    />
                    <Route exact path="/">
                        <Home/>
                    </Route>
                </Router>
            </div>
        </React.Fragment>
    );
}

export default App;
