import { createStyles, makeStyles } from '@material-ui/core/styles';
import { menuBreakPoint } from './settings';

export const useStyleSections = makeStyles(theme =>
    createStyles({
        section: {
            marginBottom: theme.spacing(8),
            [theme.breakpoints.down(menuBreakPoint)]: {
                marginBottom: theme.spacing(5),
            },
        },
        sectionHeading: {
            borderBottom: '3px solid white',
            display: 'inline-block',
            fontWeight: theme.fontWeight.medium,
            fontSize: theme.spacing(4.5),
            textTransform: 'uppercase',
            [theme.breakpoints.down(menuBreakPoint)]: {
                fontSize: theme.spacing(3),
                marginBottom: theme.spacing(1.5),
            },
        },
        sectionText: {
            fontSize: theme.spacing(4.5),
            fontWeight: 300,
            lineHeight: 1.4,
            paddingTop: theme.spacing(3),
            [theme.breakpoints.down(menuBreakPoint)]: {
                fontSize: theme.spacing(3),
                paddingTop: theme.spacing(1),
            },
        },
    }),
);
