import React from 'react';
import {getBackgrounds} from '../util/image';
import logo from '../assets/images/logo-white.svg';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import BackgroundSlider from 'react-background-slider';
import {withTemplate} from './Template';
import {menuBreakPoint} from '../util/settings';

const logoWidth = 600;
const logoWidthXs = 250;
const useStyles = makeStyles(theme =>
    createStyles({
        logo: {
            position: 'absolute',
            top: '30%',
            left: '50%',
            width: `${logoWidth}px`,
            marginLeft: `-${logoWidth / 2}px`,
            [theme.breakpoints.down(menuBreakPoint)]: {
                width: `${logoWidthXs}px`,
                marginLeft: `-${logoWidthXs / 2}px`,
            },
        },
        background: {
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
    }),
);

const Home = () => {
    const classes = useStyles();
    return (
        <div className={classes.background}>
            <BackgroundSlider images={getBackgrounds()} transition={0.5} duration={5}/>
            <img alt="Abraxas" src={logo} className={classes.logo}/>
        </div>
    );
};

export default withTemplate(Home);
