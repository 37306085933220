import React, { useState } from 'react';
import Topnav from './header/Topnav';
import Drawer from './drawer/Drawer';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        fontFamily: "'Yamanatrav', sans-serif",
    },
    fontWeight: {
        thin: 100,
        light: 300,
        regular: 400,
        medium: 500,
        mediumPlus: 600,
        bold: 700,
        black: 900,
    },
});

export const withTemplate = Component => props => {
    const { activeItem, color } = props;
    const [isDrawerOpen, toggleDrawer] = useState(false);
    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Topnav toggleDrawer={val => toggleDrawer(val)} heading={activeItem} color={color} />
                <Drawer isDrawerOpen={isDrawerOpen} toggleDrawer={val => toggleDrawer(val)} activeItem={activeItem} />
                <Component {...props} />
            </ThemeProvider>
        </React.Fragment>
    );
};
